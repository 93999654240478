<template lang="pug">
.ToolbarLogo.nav-item
  #ToolbarLogo.ToolbarLogo-Logo.dropdown-toggle(
    @click='toggleShow',
    ref='logo'
  )
    img.ToolbarLogo-Image(src='../../assets/images/yamp_logo.png')
    span.ToolbarLogo-ProjectName {{ projectName }}
    i.fas.fa-caret-down

  Transition(name='scale-up')
    .ToolbarLogo-Menu.dropdown-menu(v-show='show')

      ja-user-menu(
        :on-logout='logout'
        :user-name='userName'
      )

      ja-projects-tree(
        :active-project-id='activeProjectId'
        :organizations='organizations'
        :on-select-project='assingProject'
        show-organization-menu
      )
</template>

<script>
import { assingProject } from '@apps/Accounts/Organizations/Users/store/users.store'
import { isAdmin } from '@utils'
import state, { getCurrentUser, logout } from '@apps/Common/common.store'

export default {
  name: 'ToolbarLogo',

  data: () => ({
    show: false,
  }),

  computed: {
    isAdmin,

    activeProjectId () {
      return state.user?.project_id || 0
    },

    organizations () {
      return state.user?.organizations || []
    },

    projectName () {
      return state.user?.project?.name || ''
    },

    userName () {
      return state.user?.name || ''
    },
  },

  mounted () {
    document.addEventListener('click', (event) => {
      if (
        event.target !== this.$refs.logo &&
        !this.$refs.logo?.contains(event.target)
      ) {
        this.show = false
      }
    })
  },

  methods: {
    toggleShow () {
      this.show = !this.show
    },

    async logout () {
      await logout()
      this.$router.push({ name: 'login' })
    },

    async assingProject (project) {
      await assingProject(state.user.id, project.id)
      await getCurrentUser()
      this.$router.push({
        name: 'home',
        query: { _pid: project.id }
      })
    }
  },
}
</script>

<style lang="sass" scoped>
@import @plugins/ja/styles/variables

$parent: ".ToolbarLogo"

#{$parent}
  color: white
  transition: all 0.15s ease-in-out
  &:hover
    background-color: rgba(0, 0, 0, 0.15)

  &-Image
    display: inline-block
    max-height: 24px

  &-Logo
    cursor: pointer
    i
      margin-left: 10px
      margin-right: 0

  &-Menu
    display: grid
    backgroud-color: white
    line-height: 30px
    padding: 0
    ul
      margin-bottom: 0

  &-MenuLink
    &:active
      color: white
      background-color: $purple

  &-ProjectName
    padding: 0 8px 0 6px

@media (max-width: 1170px)
  #{$parent}
    &-ProjectName
      display: none

.scale-up-enter-active,
.scale-up-leave-active
  transition: all 0.25s ease
  transform-origin: left 0px

.scale-up-enter-active
  opacity: 1
  transform: scale(1)

.scale-up-enter,
.scale-up-enter-from,
.scale-up-leave-active,
.scale-up-leave-to
  opacity: 0
  transform: scale(0)
</style>
