import ApiClient from '@api/client'

const client = new ApiClient({ path: '/campaigns' })
const clientContent = new ApiClient({ path: '/campaign-contents' })
const clientAnalytics = new ApiClient({ path: '/analytics' })

export const CampaignService = {
  /**
   * Add Audience
   * @param {Number|String} id
   * @param {Object|String} audience
   */
  async addAudience (id, audience) {
    return await client.post(`/${id}/audiences/${audience}`)
  },

  /**
   * Create Campaign
   */
  async create (data) {
    return await client.post('/', data)
  },

  /**
   * Create Campaign Content
   */
  async createContent (data) {
    return await clientContent.post('/', data)
  },

  /**
   * Delete Campaign
   */
  async delete (id) {
    return await client.delete(`/${id}`)
  },

  /**
   * Get Campaign By Id
   * @return {Array} campaigns
   */
  async getById (id) {
    return await client.get(`/${id}`)
  },

  /**
   * Get AMP Blocks Stats by Campaign
   * @return {Array} yamp-blocks-stats
   */
  async getAmpBlockStatsByCampaign (id) {
    return await clientAnalytics.get('/yamp-blocks-stats', {
      params: { filter_type: 'campaign', filter_id: id },
    })
  },

  /**
   * Remove Audience
   * @param {Number|String} id
   * @param {Object|String} audience
   */
  async removeAudience (id, audience) {
    return await client.delete(`/${id}/audiences/${audience}`)
  },

  /**
   * Schedule campaign confirming the job created
   */
  async schedule (id, data) {
    return await client.post(`/${id}/schedule`, data)
  },

  /**
   * Send Email
   */
  async sendEmail (id, data) {
    return await client.post(`/${id}/send`, data)
  },

  /**
   * Update Campaign
   */
  async update (id, data) {
    return await client.put(`/${id}`, data)
  },

  /**
   * Update Campaign Content
   */
  async updateContent (id, data) {
    return await clientContent.put(`/${id}`, data)
  },

  /**
   * Update Campaign Preview
   */
  async updateContentPreview (id, data) {
    return await clientContent.post(`/${id}/preview`, data, {
      headers: { 'content-type': 'multipart/form-data' },
    })
  },
}
