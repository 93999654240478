<template lang="pug">
ja-content(:pageTitle='$t("teams")' :page-subtitle='pageSubtitle')

  ja-data-table(
    v-if='teams.length'
    :items='teams'
    :fields='fields'
    :pagination='pagination'
    :on-change-page='handleChangePage'
    :on-row-click='(row) => jaDetailView("team", row)'
  )
</template>

<script>
import { formatDate } from '@utils'
import state, {
  getTeamsByOrganizationId,
} from '../Organizations/store/organizations.store'

export default {
  name: 'TeamList',

  data () {
    const pageSubtitle = 'Create groups of users for customized accessibility and permission settings.'
    const fields = [{ key: 'id', label: 'Id Team' }, 'name', 'created']

    return {
      fields,
      pageSubtitle
    }
  },

  computed: {
    teams () {
      return state.teams?.items?.map(({ id, name, created_at }) => {
        const created = formatDate({ date: created_at })
        return { id, name, created }
      }) || []
    },

    pagination () {
      return state?.teams?.pagination
    },
  },

  beforeCreate () {
    getTeamsByOrganizationId(this.$route.params.id_organization)
  },

  methods: {
    handleChangePage (page) {
      getTeamsByOrganizationId(this.$route.params.id_organization, {
        pagination: { page },
      })
    },
  },
}
</script>
