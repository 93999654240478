<template lang="pug">
.ProjectsTree

  .OrganizationBox(
    v-for='organization in organizationsUniq'
    v-if='organization.projects.length'
  )

    .OrganizationMenu(v-if='showOrganizationMenu')
      router-link.OrganizationName(
        :to='{ name: "organization", params: { id_organization: organization.id } }'
      )
        i.mdi.mdi-city
        span {{ organization.name }}

      ul.OrganizationLinks
        li
          router-link(
            :to='{ name: "organization-usage", params: { id_organization: organization.id } }'
          ) {{ $t("usage") }}
        li
          router-link(
            :to='{ name: "organization-plan", params: { id_organization: organization.id } }'
          ) {{ $t("plan_billing") }}

    .OrganiationDetails(v-else)
      .OrganizationName
        i.mdi.mdi-city
        span.font-bold {{ organization.name }}

    .ProjectList
      .ProjectItem(
        v-for='project in organization.projects'
        @click='openProject(project)'
        :class='{ active: project.id === activeProjectId }'
      )
        i.mdi.mdi-briefcase
        span.ProjectItem-Name {{ project.name }}
        i.mdi.mdi-chevron-right
</template>

<script>
import { uniqBy } from 'lodash'

export default {
  name: 'ProjectsTree',

  props: {
    activeProjectId: {
      type: Number,
      default: 0,
    },

    organizations: {
      type: Array,
      required: true,
    },

    onSelectProject: {
      type: Function,
      default: () => {},
    },

    showOrganizationMenu: {
      type: Boolean,
      default: false,
    }
  },

  data () {
    const organizationsUniq = this.organizations.map(organization => {
      return {
        ...organization,
        projects: uniqBy(organization.projects, 'id').sort((p1, p2) => p1.id - p2.id)
      }
    })

    return { organizationsUniq }
  },

  methods: {
    openProject (project) {
      this.onSelectProject(project)
    }
  },
}
</script>

<style lang="sass" scoped>
@import @plugins/ja/styles/variables

.OrganizationBox
  display: grid
  grid-template-columns: 2fr 3fr
  grid-template-areas: "organization projects" "organizationlinks projects"
  border-bottom: 1px solid #ecebf1
  padding-bottom: 18px

.OrganizationLinks
  color: $purple
  font-size: 14px
  grid-area: organizationlinks
  margin-bottom: 0
  padding-left: 0
  text-transform: capitalize
  a
    display: block
    &:hover
      color: $purple-dark
      text-decoration: underline

a.OrganizationName
  color: #93949f
  display: block
  font-weight: bold
  grid-area: organization
  &:hover
    color: $purple-dark
    text-decoration: underline

.ProjectsTree
  overflow-y: scroll
  max-height: calc(100vh - 160px)
  background-color: #f9f7fe
  display: grid
  gap: 18px
  padding: 24px 24px 2px 24px

.ProjectList
  border-left: 1px solid #ecebf1
  grid-area: projects
  padding-left: 10px

.ProjectItem
  color: #3f4148
  padding: 4px 8px
  position: relative
  i:last-child
    visibility: hidden
  &:hover, &.active
    background-color: #f3f2f8
    border-radius: 5px
    color: $purple
    cursor: pointer
  &:hover
    i:last-child
      visibility: visible
      position: absolute
      right: 0
</style>
